@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

body {
	font-family: 'Open Sans', sans-serif;
	@apply .text-gray-600;
}

.screen-reader {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

.container {
	@apply .px-6;
}

@media (min-width: 768px) {
	.container {
		@apply .px-4;
	}
}

@media (min-width: 1024px) {
	.container {
		@apply .px-2;
	}
}

@media (min-width: 1280px) {
	.container {
		@apply .px-0;
	}
}

.gradient {
	background: #177aa2; /* Old browsers */
	background: -moz-linear-gradient(45deg,  #177aa2 0%, #6cbe58 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#177aa2), color-stop(100%,#6cbe58)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(45deg,  #177aa2 0%,#6cbe58 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(45deg,  #177aa2 0%,#6cbe58 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(45deg,  #177aa2 0%,#6cbe58 100%); /* IE10+ */
	background: linear-gradient(45deg,  #177aa2 0%,#6cbe58 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#177aa2', endColorstr='#6cbe58',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.brand {
	@apply .font-light .align-middle;
}

.brand span {
	@apply .font-bold;
}

.social-menu {
	@apply .flex  .flex-grow .items-center .justify-end;
}

.social-menu a {
	@apply .flex .w-6 .h-6 .text-white .ml-6;
}
.social-menu a:hover {
	color: rgba(255, 255, 255, 0.75);
}
.social-menu a svg {
	@apply .fill-current .w-full .h-full;
}

.social-menu a:first-child {
	@apply .ml-0;
}

.laravel path {
	fill: #ff2d20 !important;
}

.counter {
	@apply .w-32 .h-32 .p-8 .rounded-full .bg-white .border-2 .shadow-xl .flex .items-center .flex-none .cursor-pointer;
	transition: all 0.5s ease;
}
.counter:hover {
	@apply .p-6;
}

.counter-sm {
	@apply .counter .w-24 .h-24 .p-6;
}
.counter-sm:hover {
	@apply .p-4;
}

.counter svg,
.counter-sm svg {
	@apply .flex-1;
}
.counter path,
.counter-sm path{
	@apply .fill-current .text-gray-500;
}

/**
 * Form
 */
form {
	@apply .flex;
}

form input {
	@apply .shadow .appearance-none .border .rounded-l .py-4 .px-5 .text-gray-500 .leading-tight;
	width: 20rem;
}
form input:focus {
	@apply .outline-none .shadow-outline;
}

form button {
	@apply .shadow .appearance-none .border .rounded-r .py-4 .px-5 .text-white .leading-tight .uppercase;
}
form button:hover {
	background: rgba(255, 255, 255, 0.25);
}

.cta-message {
	@apply .text-xl .mb-4;
}

.cta-message.error {
	@apply .text-red-600;
}

/**
 * Header
 */
header {
	@apply .pt-8 .text-white;
}

header nav {
	@apply .flex .flex-col .items-center .pb-8;
}

header .logo {
	@apply .block .w-16 .h-16 .text-white;
}
header .logo:hover {
	color: rgba(255, 255, 255, 0.75);
}
header .logo svg {
	@apply .w-auto .h-auto .fill-current;
}

header .brand {
	@apply .text-5xl;
}

@media (min-width: 768px) {
	header nav {
		@apply .flex-row;
	}

	header .logo {
		@apply .mr-2;
	}
}

/**
 * Banner
 */
#banner {
	@apply .flex .flex-col .pt-16 .pb-48;
}

#banner h1 {
	@apply .text-4xl .font-light .uppercase .mb-4;
}

#banner h2 {
	@apply .text-xl .font-light;
}

@media (min-width: 768px) {
	#banner {
		@apply .py-48;
	}

	#banner h1 {
		@apply .text-5xl;
	}
}

/**
 * CTA
 */
#cta {
	@apply .mt-24;
}

#cta h4 {
	@apply .mb-4 .text-xl .font-light .uppercase;
}

#cta a {
	@apply .underline;
}

/**
 * Icons
 */
#icons {
	@apply .flex .items-center .justify-around .absolute .left-0 .w-full;
	margin-top: -66px;
}

#icons .counter-sm {
	display: none;
}

@media (min-width: 768px) {
	#icons .counter-sm {
		@apply .flex;
	}
}

/**
 * Architecture
 */
#architecture {
	@apply .bg-gray-200 .py-32;
}

#architecture p {
	@apply .font-light .text-xl .mb-4;
}

.detail,
.detail-right {
	@apply .flex .flex-col .items-center .relative;
}

.detail .icon,
.detail-right .icon {
	@apply .absolute;
	top: -66px;
}

.detail .content,
.detail-right .content {
	@apply .py-32;
}

.detail .content h3,
.detail-right .content h3 {
	@apply .text-3xl .font-light .uppercase .mb-8 .text-center;
}

.detail .content h3:not(:first-child),
.detail-right .content h3:not(:first-child) {
	@apply .mt-8;
}

.detail .content p,
.detail-right .content p{
	@apply .font-light .text-xl .mb-4;
}

.detail .content p:last-child,
.detail-right .content p:last-child{
	@apply .mb-0;
}

.detail .content ul,
.detail-right .content ul {
	@apply .list-disc .ml-8;
}

.detail .content li,
.detail-right .content li {
	@apply .font-light;
}

@media (min-width: 768px) {
	.detail,
	.detail-right {
		@apply .flex-row;
	}

	.detail .icon,
	.detail-right .icon {
		@apply .static;
	}
	.detail .icon {
		@apply .mr-24;
	}
	.detail-right .icon {
		@apply .ml-24 .order-last;
	}

	.detail .content h3,
	.detail-right .content h3 {
		@apply .text-left;
	}
}


.footer-cta {
	@apply .gradient .text-white .text-center .py-48;
}
.footer-cta h4 {
	@apply .text-3xl .font-light .uppercase .mb-8;
}
.footer-cta form {
	@apply .flex .self-center .max-w-full;
}

.footer-cta .newsletter {
	@apply .flex .flex-col .justify-center;
}

.footer-cta a {
	@apply .underline;
}

/**
 * Footer
 */
footer {
	@apply .py-20 .bg-gray-900 .text-white;
}

footer .about {
	@apply .flex .flex-col .flex-grow;
}

footer .about .logo {
	@apply .flex .items-center .mb-4;
}

footer .about .logo a {
	@apply .mr-2;
}

footer .about .brand {
	@apply .text-3xl;
}

footer .about .tagline {
	@apply .uppercase .mb-8 .font-light;
}

footer p {
	@apply .text-sm .text-gray-500 .mb-2;
}

@media (min-width: 768px) {
	footer .container > div {
		@apply .flex .items-end;
	}
}
